<template>
  <v-container fluid>
    <v-card elevation="4" class="mt-0">
      <v-tabs v-model="tab" @change="tabChanged()">
        <v-tab v-for="item in items" :key="item.tab">
          {{ item.tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.tab">
          <products-tab v-if="item.content == 'products'"></products-tab>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
    ProductsTab: () => import("./statistics-components/ProductsTab.vue"),
  },

  async mounted() {
    try {
      await this.$store.dispatch("providers/list");
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  data() {
    return {
      tab: null,
      items: [
        {
          tab: this.$t("products.products"),
          content: "products",
          type: "products",
        },
      ],
    };
  },

  methods: {
    async tabChanged() {
      try {
        this.$store.commit("statistics/CLEAN_LIST");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
